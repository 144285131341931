import * as React from 'react';
import {graphql} from 'gatsby';
import EventDaysList from '../../components/agenda/EventDaysList';
import {Speech, SpeechDAO} from '../../models/Speech';
import NorthAmericaBasePageLayout from "../../layouts/NorthAmericaBasePageLayout";
import {Section} from "../../components/Section";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface AgendaPageProps {
    data: {
        allFile: {
            nodes: SpeechDAO[]
        }
    };
}

const AgendaPage: React.FC<AgendaPageProps> = ({data}) => {
    const eventsGroupedByStartDate: any[] = data.allFile.nodes
        .map(edge => Speech.deserialize(edge, 'EST'))
        .sort((speechA, speechB) => speechA.order - speechB.order)
        .reduce<any[]>((arr, value) => {
            if (arr[arr.length - 1]?.[0] !== value.startDate) {
                arr.push([value.startDate, []]);
            }

            arr[arr.length - 1][1].push(value);

            return arr;
        }, [])

    return (
        <NorthAmericaBasePageLayout location='/north-america/agenda'>
            <Section>
                <h1 className="soul-heading soul-heading--h1">Agenda</h1>
            </Section>
            <EventDaysList startDateEventsMap={eventsGroupedByStartDate}></EventDaysList>
        </NorthAmericaBasePageLayout>
    );
};

export const pageQuery = graphql`
query NASpeechesQuery {
  allFile(filter: {relativeDirectory: {eq: "north-america/speeches"}, extension: {eq: "md"}}, sort: {fields: [childMarkdownRemark___frontmatter___title], order: [ASC]}) {
    nodes {
      id
      childMarkdownRemark {
        html
        fields {
          slug
        }
        frontmatter {
          title
          startDateTime
          endDateTime
          speaker {
            childMarkdownRemark {
              html    
              fields {
                slug
              }
              frontmatter {
                firstName
                lastName
                jobTitle
                organization
              }
            }
          }
        }
      }
    }
  }
}
`;

export default AgendaPage;
